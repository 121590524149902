<template>
  <v-card flat>
    <v-card-text>
      <EditTypography isEditingTitle/>
      <EditTypography />

      <v-row class="ma-0">
        <v-col cols="4" class="">
          <v-card-title class="font-weight-medium px-0 mx-0 text-body-1">Redes sociais</v-card-title>
          <SelectColor :color="corRedesSociais" @setNewColor="setSocialColor" reset-color="#00000000" />

        </v-col>
        <v-col cols="3" />
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import SelectColor from "@/components/personalize/SelectColor";
import EditTypography from "@/components/personalize/EditTypography";
import { mapGetters, mapMutations } from "vuex"
export default {
  name: "Design",
  components: { SelectColor, EditTypography },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(["corRedesSociais"]),

    socialColor: {
      get() {
        return this.corRedesSociais;
      },
      set(new_value) {
        this.setCorRedesSociais(new_value);
      }
    },
  },
  methods: {
    ...mapMutations(["setCorRedesSociais"]),

    setSocialColor(color) {
      this.socialColor = color;
    },
  }
}
</script>