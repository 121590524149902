<template>
    <div :class="`${isEmbed ? 'centered-container' : ''}`">>
        <transition appear name="fade">
            <v-container style="max-width: 1200px;">
                <div class="text-center white--text text-h4 font-weight-medium">
                    <p>Sua nova <span class="secondary--text">assinatura</span> está quase lá!</p>
                    <p>Para finalizar basta <span class="secondary-underline">preencher os dados</span> abaixo.</p>
                </div>
                <v-row class="mt-16">
                    <v-col>
                        <v-text-field class="my-8 mx-4" v-model="firstname" placeholder="Luiz Gustavo"
                            persistent-placeholder label="Nome *" required dark filled :rules="[rules.firstname]" />

                        <v-text-field v-model="userEmail" class="my-8 mx-4" label="E-mail *"
                            placeholder="luiz@conectasuite.com.br" persistent-placeholder required dark filled
                            :rules="[rules.email]" />
                    </v-col>


                    <v-col>
                        <v-text-field v-model="userCellphone" class="my-8 mx-4" label="Número de telefone *"
                            placeholder="Ex: +55 51 998349326" persistent-placeholder required dark filled
                            :rules="[rules.cellphone]" />

                        <v-select v-model="numberofemployees" :items="optionsEmployees" label="Número de colaboradores *" class="my-8 mx-4"
                            placeholder="Ex: 25-50" persistent-placeholder required dark filled
                            :rules="[rules.numberofemployees]" />
                    </v-col>
                </v-row>
                <div class="d-flex column align-center justify-center white--text">
                    <v-checkbox v-model="acceptedCheckbox" color="secondary" dark></v-checkbox><span>Eu concordo em receber
                        outras comunicações e permito que armazenem e processem meus dados pessoais.</span>
                </div>
                <div class="text-center mb-10">
                    <small class="white--text">A Conecta Suite respeita sua privacidade e usará suas informações pessoais
                        apenas para administrar sua conta e fornecer o conteúdo solicitado. Você pode cancelar o recebimento
                        dessas comunicações a qualquer momento. Para mais informações, leia a nossa <a color="secondary"
                            href="https://conectasuite.com/politica-de-privacidade/" target="_blank">política de privacidade</a> e <a
                            href="https://conectasuite.com/termos-de-uso/" target="_blank">termos de uso</a>.</small>
                </div>
                <div class="d-flex justify-center align-center">
                    <v-btn x-large class="ma-2" :disabled="!validateForm" color="secondary" dark rounded
                        @click="finishSignature"> <v-icon v-text="'mdi-draw-pen'" class="mr-2" /> Finalizar</v-btn>
                    <v-btn x-large class="ma-2" outlined dark rounded
                        @click="(setShowInfoForm(false)), (setShowEditor(true))"> <v-icon v-text="'mdi-arrow-left'"
                            class="mr-2" /> Voltar para o editor</v-btn>
                </div>
                <div v-if="!isEmbed" class="text-center my-8">
                    <a href="https://conectasuite.com/" target=”_blank”><v-btn small elevation="0" class="secondary--text text--lighten-1" dark color="primary">visite nosso site</v-btn></a>
                </div>
            </v-container>
        </transition>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import axios from 'axios'

export default {
    data() {
        return {
            acceptedCheckbox: false,
            numberofemployees: "",
            optionsEmployees: ['1-5', '5-25', '25-50', '50-100', '100-500'],
            rules: {
                cellphone: value => value != "" || "Insira um telefone celular válido",
                email: value => value != "" || "Insira uma caixa de e-mail válida",
                firstname: value => value != "" || "Insira seu nome",
                numberofemployees: value => value != "" || "Selecione uma opção",
            }
        }
    },
    methods: {
        ...mapMutations([
            "setName",
            "setEmail",
            "setCellphone",
            "setShowEditor",
            "setShowInfoForm",
            "setShowFinalSignature",
            "setShowSuccessCopy",
        ]),

        registerUserLeadHubSpot() {
            const url = "https://hubspot-service-fwizitgn6a-rj.a.run.app/hubspot/submit-form"; // PROD
            // const url = "https://dev-hubspot-service-mzkry6vcpq-rj.a.run.app/hubspot/submit-form";// DEV
            // const url = "http://127.0.0.1:5000/hubspot/submit-form"; // LOCAL

            const payload = {
                "form_name": "gerador_de_assinatura",
                "page_name": "Gerador de assinatura",
                "contact": {
                    "email": this.userEmail,
                    "firstname": this.firstname,
                    "phone": this.userCellphone,
                    "numemployees": this.numberofemployees,
                },
                "company": {
                }
            };

            axios.post(url, payload)
        },

        finishSignature() {

            this.registerUserLeadHubSpot();

            this.setShowInfoForm(false);
            this.setShowFinalSignature(true);
        }

    },
    computed: {
        ...mapGetters([
            "name",
            "email",
            "cellphone",
            "isEmbed",
        ]),

        firstname: {
            get() {
                return this.name;
            },
            set(newVal) {
                this.setName(newVal)
            }
        },
        userEmail: {
            get() {
                return this.email;
            },
            set(newVal) {
                this.setEmail(newVal)
            }
        },
        userCellphone: {
            get() {
                return this.cellphone;
            },
            set(newVal) {
                this.setCellphone(newVal)
            }
        },
        validateForm() {
            return (this.rules.cellphone(this.userCellphone) === true && this.rules.email(this.userEmail) === true && this.rules.firstname(this.firstname) === true && this.rules.numberofemployees(this.numberofemployees) === true && this.acceptedCheckbox)
        }
    }
}
</script>

<style scoped>
.centered-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
}

a {
    color: #ec7745 !important;
}

.secondary-underline {
    text-decoration: underline wavy green 4px
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}</style>