<template>
  <table id="signatureC00" style="max-width: 600px;">
    <tr>
      <td width="170">
        <div>
          <img
            style="max-height:150px; max-width:150px; border-radius: 100%; margin: 16px auto 16px auto; object-fit: cover;"
            width="150"
            height="150"
            :src="getURLAvatar"
            alt="Avatar"
          />
        </div>
        <div v-if="logoUrl">
            <img
              style="max-height: 150px; max-width: 150px;"
              width="150"
              :src="getURLCompanyLogo"
              alt="Logotipo da Empresa"
            />
        </div>
      </td>
      <td :style="`font-family: ${typography.textFont}, geneva, sans-serif; border 1px solid; font-size: ${typography.textFontSize}; font-weight:bold; color:${textColor};`">
        <div :style="`font-family: ${typography.titleFont}, geneva, sans-serif; color:${titleColor}; font-size: ${typography.titleFontSize}; line-height: 1.2em;`">
          {{ name || "Seu nome" }}
        </div>

        <div :style="`font-weight:normal; line-height: 1.5em;`">
          <i>{{ role || "Seu cargo" }}</i>
        </div>

        <div :style="`line-height: 1.5em;`">
          {{ email || "Email" }}
        </div>

        <div>
          <div style="line-height: 1.5em;">
            {{ [phone, cellphone].filter(v => v).join(' / ') || "(11) 31234-5678 / (11) 98080-9090" }}
          </div>

          <div style="margin-top: 5px">
            <a :href="site" target="_blank" :style="`color:${titleColor}; line-height: 1.5em; text-decoration: none; `">
              {{ site || "www.site.com" }}
            </a>
          </div>

          <div
            v-if="redesSociaisComCor"
            style="
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-top: 5px;
              width: 160px;
              height: 28px;
            "
          >
            <facebook size="28" :color="corRedesSociais" />
            <instagram size="28" :color="corRedesSociais" />
            <linkedin size="28" :color="corRedesSociais" />
            <twitter size="28" :color="corRedesSociais" />
            <youtube size="28" :color="corRedesSociais" />
            <whatsapp size="28" :color="corRedesSociais" />
          </div>

          <div
            v-else
            style="
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-top: 5px;
              width: 160px;
              height: 28px;
            "
          >
            <facebook size="28" />
            <instagram size="28" />
            <linkedin size="28" />
            <twitter size="28" />
            <youtube size="28" />
            <whatsapp size="28" />
          </div>
        </div>
      </td>
    </tr>
  </table>
</template>
<script>
import { mapGetters } from "vuex";
import Facebook from "@/components/social/Facebook.vue";
import Linkedin from "@/components/social/Linkedin.vue";
import Youtube from "@/components/social/Youtube.vue";
import Twitter from "@/components/social/Twitter.vue";
import Instagram from "@/components/social/Instagram.vue";
import Whatsapp from "@/components/social/Whatsapp.vue";
import { defaultUrlAvatar, defaultLogoUrl } from "@/helpers/services/utils";


export default {
  components: { Facebook, Linkedin, Youtube, Twitter, Instagram, Whatsapp },

  data() {
    return {
      defaultUrlAvatar,
      defaultLogoUrl,
    }
  },

  computed: {
    ...mapGetters([
      "name",
      "email",
      "phone",
      "cellphone",
      "site",
      "role",
      "redesSociaisComCor",
      "corRedesSociais",
      "titleColor",
      "textColor",
      "avatarUrl",
      "typography",
      "logoUrl",
    ]),

    getURLAvatar() {
      return this.avatarUrl || this.defaultUrlAvatar;
    },
    
    getURLCompanyLogo() {
      return this.logoUrl || this.defaultLogoUrl;
    }
  },
};
</script>
