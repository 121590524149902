<template>
  <v-dialog 
    v-model="showYoutubeVideo"
    @keydown.esc="$emit(`close`)"
    @click:outside="$emit(`close`)"
    width="625"
    >
        <v-card :class="`pa-0 ma-0 pt-2 pb-4 d-flex flex-column align-center`" style="width: 100%;" color="primary" dark>
          <v-toolbar style="position: sticky; top: 5px" color="primary" dark dense flat>
            <p class="pt-4 text-h6">Veja abaixo como <span class="wavy">aplicar</span> assinatura de e-mail</p>
          </v-toolbar>

          <iframe class="ml-7 pt-2" width="560" height="315" src="https://www.youtube.com/embed/_x0HVVwYwUw?si=YbruzgooJ5BAXYrN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

          <v-card-actions class="pt-6">
              <v-btn
                class="px-4"
                rounded
                color="#EB5C1D"
                @click="openVideo()"
              >
                <v-icon left>
                  mdi-youtube
                </v-icon>
                Assistir video
                <v-icon small right>
                  mdi-open-in-new
                </v-icon>
              </v-btn>
              <v-btn
                text
                @click="showYoutubeVideo = false"
              >
                Não, obrigado
              </v-btn>
              <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "YoutubeVideo",

  props: {
    show: { type: Boolean, required: true },
  },

  data() {
    return {};
  },

  methods: {
    openVideo() {
      let url = "https://youtu.be/_x0HVVwYwUw";
      window.open(url, "_blank");
    }
  },

  computed: {
    showYoutubeVideo: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },
  },
};
</script>

<style scoped>
.wavy {
  text-decoration-line: underline;
  text-decoration-style: wavy;
  text-decoration-color: green;
  text-decoration-thickness: 3px;
  text-decoration-skip-ink: none;
}
</style>
