<template>
  <a v-if="whatsappNumber && isWhatsapp" :href="`https://wa.me/+55${whatsappNumber}`" target="_blank" class="pa-0 ma-0">
    <div :style="`width:${size}px; height:${size}px;background: ${color};display: flex;justify-content: center;align-items: center; margin-right: 5px;`">
      <img
        src="https://drive.google.com/thumbnail?id=1SXXsamelNzW5GB00HTEtyn5h5hVLSTza&sz=w1000"
        :width="size"
        alt="Whatsapp"
        title="Whatsapp"
        @error="handleImageError"
      />
    </div>
  </a>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    size: { type: [Number, String], default: 25 },
    color: { type: String, default: '#26D366' },
  },
  computed: {
    ...mapGetters(["isWhatsapp", "cellphone"]),

    whatsappNumber() {
      return this.cellphone ? this.cellphone.replace(/[( )-]/g,'') : false;
    }
  },
  methods: {
    handleImageError(event) {
      event.target.src = "https://lh3.googleusercontent.com/drive-viewer/AEYmBYQ8ngq1AGZfio9TfctgSl7tiDf464n1UQvm0vINpj4z3aepJscHjv7puvQ5Wf2mFSRu4eltQOfiCcE2a6A2jxP2SIA2ZQ=s2560";
    },
  }
}
</script>
