<template>
  <v-card width="90%" height="500px">
    <v-toolbar style="position: sticky; top: 0" color="#404040" dark dense flat>
      <h4>Pré-visualização da mensagem</h4>
    </v-toolbar>
    <div v-if="$vuetify.breakpoint.lgAndUp">
      <v-card-subtitle class="py-1">
        De
        <span class="px-2">{{ email || "Teste (teste@mail.com)" }} </span>
      </v-card-subtitle>
      <v-divider class="mx-4" />

      <v-card-subtitle class="py-1">Para
        <span class="px-2"> Seu destinatário </span>
      </v-card-subtitle>
      <v-divider class="mx-4" />

      <v-card-subtitle class="py-1">
        Assunto
        <span class="px-2">Verifique a nova assinatura de e-mail!</span>
      </v-card-subtitle>
      <v-divider class="mx-4" />
    </div>

    <v-card flat class="overflow-y-auto int-v-card">
      <v-card-text transition="scale=transition" v-if="switchTemplate == 'C00'" class="px-4 py-6">
        <Model00 />
      </v-card-text>
      <v-card-text transition="scale=transition" v-if="switchTemplate == 'C01'" class="px-4 py-6">
        <Model01 />
      </v-card-text>
      <v-card-text transition="scale=transition" v-if="switchTemplate == 'C03'" class="px-4 py-6">
        <Model03 />
      </v-card-text>
      <v-card-text transition="scale=transition" v-if="switchTemplate == 'C07'" class="px-4 py-6">
        <Model07 />
      </v-card-text>
    </v-card>

    <v-card-actions class="mx-4 pb-2">
      <v-btn color="primary" disabled>Enviar</v-btn>
      <v-btn icon class="mx-2" disabled>
        <v-icon v-text="'mdi-rotate-270 mdi-attachment'" />
      </v-btn>
      <v-btn icon class="mr-2" disabled>
        <v-icon>mdi-link</v-icon>
      </v-btn>
      <v-btn icon class="mr-2" disabled>
        <v-icon>mdi-emoticon</v-icon>
      </v-btn>
      <v-btn icon class="mr-2" disabled>
        <v-icon>mdi-google-drive</v-icon>
      </v-btn>
      <v-btn icon class="mr-2" disabled>
        <v-icon>mdi-image</v-icon>
      </v-btn>
      <v-btn icon class="mr-2" disabled>
        <v-icon>mdi-lock</v-icon>
      </v-btn>
      <v-btn icon class="mr-2" disabled>
        <v-icon>mdi-pen</v-icon>
      </v-btn>
      <v-spacer />
      <v-btn icon class="mr-2" disabled>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
      <v-btn icon class="mr-0" disabled>
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-card-actions>

    <v-divider></v-divider>
  </v-card>
</template>
<script>
import Model00 from "@/components/templates/Model00";
import Model01 from "@/components/templates/Model01";
import Model03 from "@/components/templates/Model03";
import Model07 from "@/components/templates/Model07";

import { mapMutations, mapState, mapGetters } from "vuex";
export default {
  components: { Model00, Model01, Model03, Model07 },
  computed: {
    ...mapGetters([
      "email",
    ]),
    ...mapState(["switchTemplate"]),
  },
  methods: {
    ...mapMutations(["setSwitchTemplate"]),
  },
};
</script>
<style scoped>
.int-v-card {
  height: calc(100% - 40%)
}

@media(max-width: 1270px) {
  .int-v-card {
    height: calc(100% - 23%);
  }
}
</style>