<template>
  <div id="signatureC03">
    <p style="width: 416px; text-align: center">
      <span
        :style="`color: ${textColor}; font-family: ${typography.textFont}, verdana, geneva, sans-serif; font-size: ${typography.textFontSize}; background-color: #ffffff; margin:auto;`">
        Atenciosamente,
      </span>
    </p>
    <table style="border-collapse: collapse; width: 416px" border="0">
      <tbody>
        <tr>
          <td style="width: 401.219px">
            <img
              style="border-radius: 50%; display: block; margin: 16px auto 16px; max-height: 150px; max-width: 150px; object-fit: cover;"
              :src="getURLAvatar" alt="Avatar" width="150" height="150" />
          </td>
        </tr>
        <tr>
          <td style="width: 401.219px">
            <p
              :style="`font-family: ${typography.textFont} -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; text-align: center;`">
              <span
                :style="`font-family: ${typography.textFont} verdana, geneva, sans-serif; color: #34495e; font-size: 12pt;`">
                <strong>
                  <br />
                </strong>
              </span>
              <span
                :style="`color: ${titleColor}; font-family:${typography.titleFont}, verdana, geneva, sans-serif; font-size: ${typography.titleFontSize}`">
                <strong>{{ name || "Seu nome" }}</strong>
              </span>
              <br />
              <span :style="`font-size: ${typography.textFontSize}; color: ${textColor};`">
                <span :style="`font-family: ${typography.textFont}, verdana, geneva, sans-serif;`">
                  <i>{{ role || "Seu Cargo" }}</i>
                </span>
              </span>
              <br />
              <span :style="`font-size: ${typography.textFontSize}; color: ${textColor};`">
                <span :style="`font-family: ${typography.textFont}, verdana, geneva, sans-serif;`">
                  {{ email || "E-mail" }}
                </span>
              </span>
            </p>
            <div v-if="logoUrl">
              <img
                  style="display: block; margin: 16px auto 16px; max-height: 150px; max-width: 150px;"
                  width="150"
                  :src="getURLCompanyLogo"
                  alt="Logotipo da Empresa"
              />
            </div>
            <p
              :style="`font-family: ${typography.textFont} -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; text-align: center;`">
              <span
                :style="`font-family: ${typography.textFont}, verdana, geneva, sans-serif; font-size: ${typography.textFontSize}; color: ${textColor};`">
                <span style="color: #000000">
                  <strong>
                    <span :style="`font-family: ${typography.textFont}, verdana, geneva, sans-serif; color:${textColor}`">
                      {{
                        [phone, cellphone].filter((v) => v).join(" / ") ||
                        "(11) 31234-5678 / (11) 98080-9090"
                      }}
                    </span>
                    <br />
                  </strong>
                  <a :style="`font-family: ${typography.textFont}, verdana, geneva, sans-serif; font-size: ${typography.textFontSize}; text-decoration: none; color:${titleColor}`"
                    :href="site" target="_blank" rel="noopener">
                    {{ site || "www.seusite.com" }}
                  </a>
                </span>
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <td style="width: 401.219px">
            <span v-if="redesSociaisComCor"
              style="display: flex; flex-direction: row; align-items: center; margin-top: 5px; margin: auto; width: 190px; height: 28px; justify-content: center;">
              <facebook size="28" :color="corRedesSociais" />
              <instagram size="28" :color="corRedesSociais" />
              <linkedin size="28" :color="corRedesSociais" />
              <twitter size="28" :color="corRedesSociais" />
              <youtube size="28" :color="corRedesSociais" />
              <whatsapp size="28" :color="corRedesSociais" />
            </span>

            <span v-else
              style="
                display: flex; flex-direction: row; align-items: center; margin-top: 5px; margin: auto; width: 190px; height: 28px; justify-content: center;">
              <facebook size="28" />
              <instagram size="28" />
              <linkedin size="28" />
              <twitter size="28" />
              <youtube size="28" />
              <whatsapp size="28" />
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Facebook from "@/components/social/Facebook.vue";
import Linkedin from "@/components/social/Linkedin.vue";
import Youtube from "@/components/social/Youtube.vue";
import Twitter from "@/components/social/Twitter.vue";
import Instagram from "@/components/social/Instagram.vue";
import Whatsapp from "@/components/social/Whatsapp.vue";
import { defaultUrlAvatar, defaultLogoUrl } from "@/helpers/services/utils";

export default {
  components: { Facebook, Linkedin, Youtube, Twitter, Instagram, Whatsapp },

  data() {
    return {
      defaultUrlAvatar,
      defaultLogoUrl,
    };
  },

  computed: {
    ...mapGetters([
      "name",
      "email",
      "phone",
      "cellphone",
      "site",
      "role",
      "redesSociaisComCor",
      "corRedesSociais",
      "titleColor",
      "textColor",
      "avatarUrl",
      "typography",
      "logoUrl",
    ]),

    getURLAvatar() {
      return this.avatarUrl || this.defaultUrlAvatar;
    },
    getURLCompanyLogo() {
      return this.logoUrl || this.defaultLogoUrl;
    },
  },
};
</script>
  