import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    details: {
      name: null,
      company_name: null,
      email: null,
      site: null,
      role: null,
      phone: null,
      cellphone: null,
      address: null,
      isWhatsapp: false,
      logoUrl: null,
      avatarUrl: null,
    },
    social: {
      facebook: '',
      twitter: '',
      instagram: '',
      linkedin: '',
      youtube: '',
    },
    colors: {
      title: '#EB5C1D',
      text: '#636363FF',
    },
    corRedesSociais: '#00000000',
    redesSociaisTipo: 'square',
    typography: {
      titleFont: "Roboto",
      titleFontSize: "24px",
      textFont: "Roboto",
      textFontSize: "14px"
    },
    showEditor: true,
    showInfoForm: false,
    showFinalSignature: false,
    showSuccessCopy: false,
    // States of templates
    switchTemplate: "C00",
    isEmbed: false,
    alreadySharedSignature: false
    
  },
  getters: {
    isEmbed: (state) => state.isEmbed,
    name: (state) => state.details.name,
    company: (state) => state.details.company_name,
    email: (state) => state.details.email,
    site: (state) => state.details.site,
    role: (state) => state.details.role,
    phone: (state) => state.details.phone,
    cellphone: (state) => state.details.cellphone,
    address: (state) => state.details.address,
    isWhatsapp: (state) => state.details.isWhatsapp,
    logoUrl: (state) => state.details.logoUrl,
    avatarUrl: (state) => state.details.avatarUrl, 

    facebook: (state) => state.social.facebook,
    instagram: (state) => state.social.instagram,
    linkedin: (state) => state.social.linkedin,
    twitter: (state) => state.social.twitter,
    youtube: (state) => state.social.youtube,

    titleColor: (state) => state.colors.title,
    textColor: (state) => state.colors.text,
    corRedesSociais: (state) => state.corRedesSociais,
    redesSociaisComCor: (state) => state.corRedesSociais !== '#00000000' && state.corRedesSociais.substring(7,9) !== '00',
    typography: (state) => state.typography,

    // Getters of Templates
    switchTemplate: (state) => state.switchTemplate,

    showEditor: (state) => state.showEditor,
    showInfoForm: (state) => state.showInfoForm,
    showFinalSignature: (state) => state.showFinalSignature,
    hasAlreadySharedSignature: (state) => state.alreadySharedSignature
  },
  mutations: {
    setIsEmbed(state, status) {
      state.isEmbed = status;
    },
    setShowEditor(state, show) {
      state.showEditor = show;
    },
    setShowInfoForm(state, show) {
      state.showInfoForm = show;
    },
    setShowFinalSignature(state, show) {
      state.showFinalSignature = show;
    },
    setSocialLink(state, content) {
      state.social[content.tag] = content.value;
    },
    setTitleColor(state, color) {
      state.colors.title = color;
    },
    setTextColor(state, color) {
      state.colors.text = color;
    },
    setCorRedesSociais(state, color) {
      state.corRedesSociais = color;
    },
    setTipoRedesSociais(state, tipo) {
      state.redesSociaisTipo = tipo;
    },
    setName(state, name){
      state.details.name = name;
    },
    setCompany(state, company){
      state.details.company_name = company;
    },
    setEmail(state, email){
      state.details.email = email;
    },
    setSite(state, site){
      state.details.site = site;
    },
    setRole(state, role){
      state.details.role = role;
    },
    setPhone(state, phone){
      state.details.phone = phone;
    },
    setCellphone(state, cellphone){
      state.details.cellphone = cellphone;
    },
    setAddress(state, address){
      state.details.address = address;
    },
    setIsWhatsapp(state, isWhatsapp){
      state.details.isWhatsapp = isWhatsapp;
    },
    setLogoUrl(state, logoUrl) {
      state.details.logoUrl = logoUrl;
    },
    setAvatarUrl(state, avatarUrl) {
      state.details.avatarUrl = avatarUrl;
    },
    setTitle(state, titleFont) {
      state.typography.titleFont = titleFont
    },
    setTitleFontSize(state, titleFontSize) {
      state.typography.titleFontSize = titleFontSize;
    },
    setText(state, textFont) {
      state.typography.textFont = textFont;
    },
    setTextFontSize(state, textFontSize) {
      state.typography.textFontSize = textFontSize;
    },

    setAlreadySharedSignature(state, status = true) {
      state.alreadySharedSignature = status
    },

    // Mutations of Templates
    setSwitchTemplate(state, template_id) {
      state.switchTemplate = template_id
    },
  },
  actions: {
  },
  modules: {
  }
})
