<template>
  <a v-if="youtube" :href="`https://www.youtube.com/c/${youtube}`" target="_blank" class="pa-0 ma-0">
    <div :style="`width:${size}px; height:${size}px;background: ${color};display: flex;justify-content: center;align-items: center; margin-right: 5px;`">
      <img
        src="https://drive.google.com/thumbnail?id=1Nmx2iem7lLN48LuFxkkq-JFGeMZEIVu6&sz=w1000"
        :width="size"
        alt="youtube"
        title="Youtube"
        @error="handleImageError"
      />
    </div>
  </a>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    size: { type: [Number, String], default: 25 },
    color: { type: String, default: '#FF0000FF' },
  },
  computed: {
    ...mapGetters(["youtube"]),
  },
  methods: {
    handleImageError(event) {
      event.target.src = "https://lh3.googleusercontent.com/drive-viewer/AEYmBYRhNdt4J10EdRyhpF2eJ0N2JoMoGRbgpsULR9tmW3kdQAZIXy5HBVdqU2CtrVIoq9j9o899YPYBiGfhZVTYXtU4y2_IFA=s2560";
    },
  }
}
</script>
