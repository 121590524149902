<template>
  <a v-if="instagram" :href="`https://www.instagram.com/${instagram}`" target="_blank" class="pa-0 ma-0">
    <div :style="`width:${size}px; height:${size}px;background: ${color};display: flex;justify-content: center;align-items: center; margin-right: 5px;`">
      <img
        src="https://drive.google.com/thumbnail?id=1R9SMUwxmPIUOF88PRTCwlO6fY2RyuFK6&sz=w1000"
        :width="size"
        alt="instagram"
        title="Instagram"
        @error="handleImageError"
      />
    </div>
  </a>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    size: { type: [Number, String], default: 25 },
    color: { type: String, default: 'radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%)' },
  },
  computed: {
    ...mapGetters(["instagram"]),
  },
  methods: {
    handleImageError(event) {
      event.target.src = "https://lh3.googleusercontent.com/drive-viewer/AEYmBYQtGrjjOFtsjU7WVZXTrrldx-SRCiJ_021eXfPeHXU_zn9CqMXbeViLtbTobP8o92cqcSk-ElY_QmMkhcB6zyjppQz1eQ=s2560";
    },
  }
}
</script>

