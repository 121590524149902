<template>
  <v-card flat>
    <v-card-text>
      <v-row class="ma-0">
        <v-col cols="12">
          <v-card-title class="font-weight-medium px-0 mx-0 text-body-1">
            Modelos de Templates
          </v-card-title>
          <v-container class="ma-0 pa-0">
            <v-row class="ma-0 pa-0 resp-row">
              <v-col v-for="(template, i) in modelsTemplates" :key="i" sm="6">
                <CardTemplate :info="template" :htmltemplate="template.html" :selected="verifyIfIsSelected(template)"
                  @selectCard="selectedTemplateCard(template)" />
              </v-col>
            </v-row>
          </v-container>
        </v-col>

      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import CardTemplate from "@/components/CardTemplate.vue";
import { mapMutations, mapState } from "vuex";
import { modelsTemplates } from "@/helpers/services/utils";

export default {
  components: {
    CardTemplate,
  },
  data() {
    return {
      modelsTemplates,
      has_selected_template: false,
    };
  },
  methods: {
    ...mapMutations(["setSwitchTemplate"]),

    selectedTemplateCard(template) {
      this.has_selected_template = template
      this.setSwitchTemplate(template.id)
    },
    verifyIfIsSelected(template) {
      return this.currentSelected
        ? this.currentSelected.id === template.id
        : false;
    },
  },
  computed: {
    ...mapState(["switchTemplate"]),

    currentSelected() {
      return this.has_selected_template || {};
    },
  },
};
</script>
<style scoped>
.resp-row {
  display: flex !important;
}

@media (max-width: 600px) {
  .resp-row {
    display: block !important;
  }
}
</style>
