<template>
  <v-row class="ma-0">
    <v-col cols="10" class="mb-0 pb-0">
      <v-card-title class="font-weight-medium px-0 pt-2 mx-0 pb-0 body-1">
        Personalizar {{isEditingTitle ? "título" : "texto"}}</v-card-title>
    </v-col>

    <v-col cols="12" sm="4">
      <v-autocomplete
        v-model="selectedFont"
        hide-details
        color="secondary"
        label="Fonte"
        :items="fontsList"
        @input="changeFont(selectedFont)"
      />
    </v-col>

    <v-col cols="12" sm="4">
      <v-autocomplete 
        v-model="selectedFontSize"
        hide-details
        label="Tamanho da fonte"
        color="secondary"
        :items="fontSizeList"
        @input="changeFontSize(selectedFontSize)"
      />
    </v-col>

    <v-col cols="12" sm="4">
      <SelectColor 
        :color="isEditingTitle ? titleColor : textColor"
        @setNewColor="setNewColor"
        reset-color="#00000000"
      />
    </v-col>
  </v-row>
</template>
<script>
import {mapMutations, mapGetters} from "vuex";
import SelectColor from "@/components/personalize/SelectColor"
export default {
  name: "EditTypography",
  components: { SelectColor },
  data () {
    return {
      fontsList: [
        "Andale Mono", "Arial","Arial Black", "Book Antiqua", "Comic Sans MS", "Courier new", "Georgia", "Helvetica","Impact",
        "Roboto", "Symbol", "Tahoma", "Terminal", "Times New Roman", "Trebuchet MS", "Verdana", "Webdings", "Wingdings"
        ],
      fontSizeList: [],
      selectedFont: "",
      selectedFontSize: "",
    }
  },
  props: {
    isEditingTitle: {type: Boolean, default: false },
  },
  created() {
    this.setFontSizes()
    if (this.isEditingTitle){
      this.selectedFont = this.typography.titleFont;
      this.selectedFontSize = this.typography.titleFontSize;
    }else {
      this.selectedFont = this.typography.textFont;
      this.selectedFontSize = this.typography.textFontSize;
    }
  },

  computed: {
    ...mapGetters(["typography", "titleColor", "textColor"]),

    color: {
      get() {
        return this.isEditingTitle ? this.titleColor : this.textColor;
      },
      set(new_value) {
        this.isEditingTitle ? this.setTitleColor(new_value) : this.setTextColor(new_value);
      }
    },
  },

  methods: {
    ...mapMutations([
      "setTitle",
      "setText",
      "setTitleFontSize",
      "setTextFontSize",
      "setTitleColor",
      "setTextColor"
    ]),

    changeFont() {
      this.isEditingTitle ?  this.setTitle(this.selectedFont) : this.setText(this.selectedFont);
    },

    changeFontSize() {
      this.isEditingTitle
        ? this.setTitleFontSize(this.selectedFontSize)
        : this.setTextFontSize(this.selectedFontSize)
    },

    setFontSizes() {
      let minFontRange = this.isEditingTitle ? 18 : 12
      const maxFontRange = this.isEditingTitle ? 30 : 20
      
      for (minFontRange; minFontRange <= maxFontRange; minFontRange++){
        this.fontSizeList.push(minFontRange + "px")
      }
    },

    setNewColor(color) {
      this.color = color;
    }
  }
}
</script>