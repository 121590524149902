<template>
  <div id="signatureC07">
    <p>
      <span
        :style="`color: ${textColor}; font-family:${typography.textFont}, verdana, geneva, sans-serif; font-size: ${typography.textFontSize}; background-color: #ffffff; margin-left: 30px`">
        Atenciosamente,
      </span>
    </p>
    <table style="border-collapse: collapse; width: 600px; height: 152px" border="0">
      <tbody>
        <tr style="height: 109px">
          <td style="width: 216.688px; text-align: center; height: 109px">
            <img style="border-radius: 50%; object-fit: cover;" :src="getURLAvatar" alt="FOTO" width="122" height="122" />
          </td>
          <td style="width: 10px; height: 109px">&nbsp;</td>
          <td style="vertical-align: top; height: 109px; width: 330.969px" rowspan="2">
            <p>
              <img
                v-if="logoUrl"
                style="max-height: 150px; max-width: 150px;"
                width="150"
                :src="getURLCompanyLogo"
                alt="Logotipo da Empresa"
              />
              <br v-if="logoUrl" />
              <span
                :style="`font-size: ${typography.textFontSize}; font-family: ${typography.textFont}, verdana, geneva, sans-serif; color: ${textColor}; font-weight: bold;`">
                {{
                  [phone, cellphone].filter((v) => v).join(" / ") ||
                  "(11) 31234-5678 / (11) 98080-9090"
                }}
              </span>
              <br />
              <span :style="`font-size: ${typography.textFontSize}; color: ${textColor}; font-weight: bold;`">
                <span :style="`font-family: ${typography.textFont}, verdana, geneva, sans-serif;`">
                  {{ email || "E-mail" }}
                </span>
              </span>
              <br />
              <span>
                <a :style="`font-family: ${typography.textFont}, verdana, geneva, sans-serif; font-size: ${typography.textFontSize}; text-decoration: none; color:${titleColor}`"
                    :href="site" target="_blank" rel="noopener">
                    {{ site || "www.seusite.com" }}
                  </a>
              </span>
            </p>
            <p>
              <span style="font-size: 10pt; font-family: verdana, geneva, sans-serif;">
                <span style="color: #236fa1">
                  <div v-if="redesSociaisComCor"
                    style="display: flex; flex-direction: row; align-items: center; margin-top: 5px; width: 160px; height: 28px;">
                    <facebook size="28" :color="corRedesSociais" />
                    <instagram size="28" :color="corRedesSociais" />
                    <linkedin size="28" :color="corRedesSociais" />
                    <twitter size="28" :color="corRedesSociais" />
                    <youtube size="28" :color="corRedesSociais" />
                    <whatsapp size="28" :color="corRedesSociais" />
                  </div>

                  <div v-else
                    style="display: flex; flex-direction: row; align-items: center; margin-top: 5px; width: 160px; height: 28px;">
                    <facebook size="28" />
                    <instagram size="28" />
                    <linkedin size="28" />
                    <twitter size="28" />
                    <youtube size="28" />
                    <whatsapp size="28" />
                  </div>
                </span>
              </span>
            </p>
          </td>
        </tr>
        <tr style="height: 43px">
          <td style="width: 216.688px; text-align: center; height: 43px">
            <p>
              <span style="font-size: 10pt; font-family: verdana, geneva, sans-serif; color: #3598db;">
                <span
                  :style="`color: ${titleColor}; font-family:${typography.titleFont}, verdana, geneva, sans-serif;font-size:${typography.titleFontSize}`">
                  <strong>
                    {{ name || "Seu Nome" }}
                    <br />
                  </strong>
                </span>
                <span :style="`color: ${textColor}; font-family: ${typography.textFont}, verdana, geneva, sans-serif;`">
                  <span :style="`font-size: ${typography.textFontSize}`">
                    <i>{{ role || "Seu Cargo" }}</i>
                  </span>
                </span>
              </span>
            </p>
          </td>
          <td style="width: 10px; height: 43px">&nbsp;</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Facebook from "@/components/social/Facebook.vue";
import Linkedin from "@/components/social/Linkedin.vue";
import Youtube from "@/components/social/Youtube.vue";
import Twitter from "@/components/social/Twitter.vue";
import Instagram from "@/components/social/Instagram.vue";
import Whatsapp from "@/components/social/Whatsapp.vue";
import { defaultUrlAvatar, defaultLogoUrl } from "@/helpers/services/utils";

export default {
  components: { Facebook, Linkedin, Youtube, Twitter, Instagram, Whatsapp },

  data() {
    return {
      defaultUrlAvatar,
      defaultLogoUrl,
    };
  },

  computed: {
    ...mapGetters([
      "name",
      "email",
      "phone",
      "cellphone",
      "site",
      "role",
      "redesSociaisComCor",
      "corRedesSociais",
      "titleColor",
      "textColor",
      "avatarUrl",
      "typography",
      "logoUrl",
    ]),

    getURLAvatar() {
      return this.avatarUrl || this.defaultUrlAvatar;
    },
    getURLCompanyLogo() {
      return this.logoUrl || this.defaultLogoUrl;
    },
  },
};
</script>
  