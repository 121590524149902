<template>
  <v-hover v-slot="{ hover }">
    <v-card
      :height="cardHeight"
      outlined
      :hover="show"
      @click="$emit('selectCard', info.id)"
      :class="`d-flex flex-column justify-center px-4 ${selected ? 'selected-card border-large' : ''}`"
    >
      <v-card
        v-if="!hover"
        :height="cardHeight + 20"
        width="100%"
        flat
        tile
        class="pa-0 ma-0 overflow-hidden transparent"
      >
        <v-card-text
          v-html="htmltemplate"
          :class="`preview-signature-scale orange-border pa-0 ma-0 transparent`"
        />
      </v-card>
      <v-card-text v-else-if="hover" class="pa-0 ma-0">
        <v-card-title
        xl="4"
          :class="`text-body-0 font-weight-medium v-list-item__title px-0`"
          
        >
          {{ info.name }}
        </v-card-title>
        <v-card-subtitle
          class="description v-list-item__subtitle text-wrap text-truncate px-0"
        >
         {{ info.description }}
        </v-card-subtitle>
      </v-card-text>
      <v-spacer />
    </v-card>
  </v-hover>
</template>
<script>

export default {
  name: "TemplateCard",
  props: {
    info: { type: Object, default: () => {} },
    show: { type: Boolean, default: false },
    selected: { type: Boolean, default: false },
    add: { type: Boolean, default: false },
    htmltemplate: { type: String, default: ""},
  },
  data() {
    return {
      html: "",
      sidebarMini: false,
    };
  },
  computed: {
    cardHeight() {
      return this.$vuetify.breakpoint.xl ? 190 : 160;
    },
  },
  methods: {
  },
};
</script>
<style scoped>
.selected-card {
  border-color: #EB5C1D;
}
.preview-signature-scale {
  position: absolute;
  max-width: 100%;
  -webkit-transform-origin: 0 0;
  border: 1px solid orange;
}
@media screen and (max-width: 1646px) {
  .preview-signature-scale {
    transform: scale(0.4);
  }
}
@media screen and (min-width: 1647px) {
  .preview-signature-scale {
    transform: scale(0.45);
  }
}
</style>
