<template>
  <a v-if="linkedin" :href="`https://www.linkedin.com/company/${linkedin}`" target="_blank" class="pa-0 ma-0">
    <div :style="`width:${size}px; height:${size}px;background: ${color};display: flex;justify-content: center;align-items: center; margin-right: 5px;`">
      <img
        src="https://drive.google.com/thumbnail?id=1zHaQexnICcVjr8QgK67i2Z2W9x4k3YQ6&sz=w1000"
        :width="size"
        alt="linkedin"
        title="Linkedin"
        @error="handleImageError"
      />
    </div>
  </a>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    size: { type: [Number, String], default: 25 },
    color: { type: String, default: '#0961B8FF' },
  },
  computed: {
    ...mapGetters(["linkedin"]),
  },
  methods: {
    handleImageError(event) {
      event.target.src = "https://lh3.googleusercontent.com/drive-viewer/AEYmBYSTLDsBQV9aV_PDUdXJkn-3Jip3d5MnruFh81o1pM-xCtfyWjHmsKbDU59YCec67tVJGRUAd3kwAax3TGINQHEDUJZK=s2560";
    },
  }
}
</script>
