<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="newColor"
        multiple
        chips
        color="secondary"
        readonly
        v-bind="attrs"
        v-on="on"
        persistent-placeholder
      >
        <template v-slot:label>
          <div class="pb-2">Cor</div>
        </template>

        <template v-slot:append>
          <v-icon v-text="'mdi-circle'" dense :color="newColor"/>
        </template>
      </v-text-field>
    </template>
    <v-card>
      <v-card-text>
        <v-color-picker
          v-model="newColor"
          show-swatches
          hide-mode-switch
          width="300"
          swatches-max-height="150px"
          mode="hexa"
          flat
      ></v-color-picker>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-btn block color="secondary" text @click="newColor = resetColor" class="body-1 font-weight-medium text-none mx-auto" small>
        Restaurar cor original
      </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>
export default {
  props: {
    color: { type: String, required: true },
    resetColor: { type: String, required: true },
  },
  data() {
    return {
      newColor: this.color,
      menu: false,
    }
  },
  watch: {
    newColor() {
      if (this.newColor) {
        this.$emit('setNewColor', this.newColor);
      }
    }
  },
  computed: {
    editColor: {
      get() {
        return this.newColor;
      },
      set(new_value) {
        this.newColor = new_value;
      }
    }
  }
}
</script>