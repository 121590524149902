<template>
    <div :class="`${isEmbed ? 'centered-container' : ''}`">
        <transition appear name="fade">
            <v-container>
                <v-row>
                    <v-col>
                        <p class="text-center white--text text-h5 font-weight-medium">
                            Feito! Sua assinatura foi criada com <span class="secondary--text">sucesso</span>. 💜
                        </p>
                        <p class="text-center white--text text-h5 font-weight-medium">
                            Agora, para aplicá-la, basta <span class="secondary-underline">adicionar</span> nas
                            configurações do seu e-mail.
                        </p>
                    </v-col>
                </v-row>
                <div class="mx-auto my-10" style="max-width: 800px;">
                    <Preview class="mx-auto" />
                </div>
                <v-card-actions class="d-flex align-center justify-center">
                    <v-btn rounded @click="copy()" color="secondary pa-3" dark x-large>
                        <v-icon v-text="'mdi-content-copy'" class="mr-2" /> Copiar assinatura
                    </v-btn>
                    <a class="white--text pa-3">
                        <v-btn rounded outlined color="white lighten-3" dark x-large @click="openSignatureSharing">
                            <v-icon v-text="'mdi-email-arrow-right-outline'" class="mr-2" /> Compartilhar por e-mail
                        </v-btn>
                    </a>
                    <v-btn small elevation="0" dark color="primary"
                        @click="(setShowFinalSignature(false)), (setShowEditor(true)), setAlreadySharedSignature(false)">
                        <v-icon v-text="'mdi-arrow-left'" class="mr-2" />
                        voltar
                    </v-btn>
                </v-card-actions>
                <div v-if="!isEmbed" class="text-center my-4">
                    <a href="https://conectasuite.com/" target=”_blank”><v-btn small elevation="0"
                            class="secondary--text text--lighten-1" dark color="primary">visite nosso site</v-btn></a>
                </div>
            </v-container>
        </transition>
        <v-snackbar v-model="showSuccessCopy" multi-line color="success" text tile top>
            <div class="text-body-1 text-center font-weight-medium"><v-icon v-text="'mdi-check-circle'" left
                    color="success" /> A assinatura foi copiada </div>
        </v-snackbar>

        <v-dialog v-model="dialog" width="1000">
            <v-card>
                <v-card-title>
                    Compartilhar assinatura por E-mail

                    <v-spacer></v-spacer>
                    <v-icon @click="dialog = false">mdi-close</v-icon>
                </v-card-title>
                <v-card-subtitle class="pt-2">
                    Insira os endereços de e-mail aos quais deseja compartilhar a assinatura de e-mail criada.
                </v-card-subtitle>

                <v-card-text>
                    <v-combobox v-model="emails" placeholder="Cole ou digite os e-mails" deletable-chips filled multiple
                        :delimiters="[',', ' ', '\n', '\t', ';']" @change="delimit" append-icon="" :rules="maxEmailsRule">
                        <template v-slot:selection="{ attrs, item, parent, selected }">
                            <v-chip v-bind="attrs" :input-value="selected" outlined>
                                <span class="pr-2">
                                    {{ item }}
                                </span>
                                <v-icon small @click="parent.selectItem(item)">
                                    mdi-close-circle
                                </v-icon>
                            </v-chip>
                        </template>

                        <template v-slot:item="{ item }">
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ item }}
                                </v-list-item-title>
                                <v-list-item-subtitle v-text="item" />
                            </v-list-item-content>
                        </template>
                    </v-combobox>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" class="px-4" elevation="0" @click="share" :loading="loading"
                        :disabled="exceededEmailsLimit">
                        <v-icon left>mdi-share</v-icon>
                        Compartilhar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="showSnackbar" multi-line :color="snackbar.color" tile top>
            <div class="text-body-1 text-center font-weight-medium">
                <v-icon :v-text="snackbar.icon" left :color="snackbar.color" />
                {{ snackbar.text }}
            </div>

            <template v-slot:action="{ attrs }">
                <v-btn text v-bind="attrs" @click="showSnackbar = false">
                    Fechar
                </v-btn>
            </template>
        </v-snackbar>
        <YoutubeVideo v-if="showYoutubeVideo" :show="showYoutubeVideo" @close="showYoutubeVideo = false" />
    </div>
</template>

<script>
import axios from 'axios';
import { EMAILS_SHARING_LIMIT } from "@/helpers/services/variables.js";
import { mapState, mapMutations, mapGetters } from 'vuex';
import Preview from "./Preview.vue";
import YoutubeVideo from './modals/YoutubeVideo.vue';

export default {
    components: {
        Preview,
        YoutubeVideo,
    },
    data() {
        return {
            showYoutubeVideo: false,
            loading: false,
            showSuccessCopy: false,
            dialog: false,
            showSnackbar: false,
            snackbar: {
                color: "",
                text: "",
                icon: ""
            },
            emails: [],
            maxEmailsRule: [v => v.length <= EMAILS_SHARING_LIMIT || 'O número máximo de e-mails permitido é 500'],
        }
    },
    methods: {
        ...mapMutations([
            "setShowEditor",
            "setShowFinalSignature",
            "setShowSuccessCopy",
            "setAlreadySharedSignature"
        ]),

        copy() {
            this.showYoutubeVideo = true;
            var additionalText = document.createElement('div');
            additionalText.innerHTML = '<small><a href="https://corp.conectasuite.com/gerador-de-assinatura">Crie uma assinatura de email profissional gratuitamente</a> </small>';

            var table = document.getElementById(`signature${this.switchTemplate}`);
            table.parentNode.insertBefore(additionalText, table.nextSibling);

            var range = document.createRange();
            range.setStartBefore(table);
            range.setEndAfter(additionalText);
            var selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);

            document.execCommand("Copy");

            additionalText.parentNode.removeChild(additionalText);

            window.getSelection()?.removeAllRanges();

            this.showSuccessCopy = true;
        },

        delimit(v) {
            const emailRegex = /\S+@\S+\.\S+/;
            const reducer = (a, e) => [...a, ...e.split(/[, ;]+/)];
            this.emails = [...new Set(v.reduce(reducer, []))].filter((email) => {
                if (emailRegex.test(email)) {
                    return email
                }
            });
        },

        share() {
            this.loading = true

            // o @change do combobox só é executado após a função share, por isso o timeout
            setTimeout(() => {
                if (this.exceededEmailsLimit || !this.emails.length) {
                    this.loading = false
                    return
                }

                const url = `${process.env.VUE_APP_NOTIFICATION_API}/share-signature`
                const payload = {
                    emails: this.emails,
                    url: this.buildSignGeneratorURL()
                }

                axios.post(url, payload).then(() => {
                    this.setSnackbarSuccess()
                    this.setAlreadySharedSignature()
                }).catch(() => {
                    this.setSnackbarError()
                }).finally(() => {
                    this.loading = false
                    this.dialog = false
                })
            }, 1000)
        },

        openSignatureSharing() {
            if (this.hasAlreadySharedSignature) return

            this.dialog = true
        },

        buildSignGeneratorURL() {
            const url = `${process.env.VUE_APP_SIGN_GENERATOR_URL}/?`
            const signParams = {
                selectedModel: this.switchTemplate,
                titleFont: this.typography.titleFont,
                titleFontSize: this.typography.titleFontSize,
                titleColor: this.titleColor,
                textFont: this.typography.textFont,
                textFontSize: this.typography.textFontSize,
                textColor: this.textColor,
                socialColor: this.corRedesSociais,
            }

            const signUrl = url + new URLSearchParams(signParams).toString();
            return signUrl
        },

        // TODO: controlar snackbars no vuex e unificar funções
        setSnackbarError() {
            this.snackbar = {
                color: "error",
                text: "Um erro ocorreu ao compartilhar a sua assinatura",
                icon: "mdi-close-circle"
            }
            this.showSnackbar = true
        },

        setSnackbarSuccess() {
            this.snackbar = {
                color: "success",
                text: "A assinatura foi compartilhada com sucesso",
                icon: "mdi-check-circle"
            }

            this.showSnackbar = true
        },
    },
    computed: {
        ...mapState(["switchTemplate"]),

        ...mapGetters(["titleColor", "textColor", "corRedesSociais", "typography", "isEmbed", "hasAlreadySharedSignature"]),

        exceededEmailsLimit() {
            return this.emails.length > EMAILS_SHARING_LIMIT
        },
    },
}

</script>

<style scoped>
.centered-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
}

.secondary-underline {
    text-decoration: underline wavy green 4px
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>