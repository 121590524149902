import Vue from "vue";
import Vuetify from "vuetify/lib";
import pt from "vuetify/es5/locale/pt";

Vue.use(Vuetify, { iconfont: "mdi" });

export default new Vuetify({
  defaultTheme: "light",
  lang: {
    locales: { pt },
    current: "pt",
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: "#331d6e",
        secondary: "#ffc153",
      },
    },
  },
});
