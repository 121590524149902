<template>
  <a v-if="facebook" :href="`https://www.facebook.com/${facebook}`" target="_blank" class="pa-0 ma-0">
    <div :style="`width:${size}px; height:${size}px;background: ${color};display: flex;justify-content: center;align-items: center; margin-right: 5px;`">
      <img 
        src="https://drive.google.com/thumbnail?id=1LYClSyb-UznkxHt9VXKnAGti5zOmAh1v&sz=w1000"
        :width="size"
        alt="facebook"
        title="Facebook"
        @error="handleImageError"
      />
    </div>
  </a>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    size: { type: [Number, String], default: 20 },
    color: { type: String, default: '#4267B2FF' },
  },
  data() {
    return {
      temporary_info: 'conectanuvem'
    }
  },
  computed: {
    ...mapGetters(["facebook"]),
  },
  methods: {
    handleImageError(event) {
      event.target.src = "https://lh3.googleusercontent.com/drive-viewer/AEYmBYQ6h6ZzhyG4BmTmhOKzVw92fAxn58Jr_RnVWdXqrzCFkjWO51C9URoSxlH_YX2K3voWUXth-J-FW4ipLywSBobMJ72Hdg=s2560";
    },
  }
}
</script>