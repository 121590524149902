<template>
  <v-card flat>
    <v-card-text>
      <v-row class="ma-0">
          <v-col cols="9" class="">
            <v-card-title class="font-weight-medium px-0 mx-0 text-body-1">Redes sociais</v-card-title>
            <v-text-field 
              v-for="(item, i) in socialItens"
              :key="i"
              v-model="social[item.tag]"
              @blur="setSocialLink({ tag: item.tag, value: social[item.tag] })"
              :label="item.label"
              color="secondary"
              :prefix="prefix[item.tag]"
              :rules="[rules.isNotALink]"
            />
          </v-col>
          <v-col cols="3" class="" />
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
  export default {
    data() {
      return {
        rules: {
          isNotALink: value => (!value.includes('http') && !value.includes('https') && !value.includes('.com') && !value.includes('www')) || 'O campo deve ser um nome de usuário'
        },
        social: {
          facebook: '',
          twitter: '',
          instagram: '',
          linkedin: '',
          youtube: '',
        },
        prefix: {
          facebook: 'facebook.com/',
          twitter: 'twitter.com/',
          instagram: 'instagram.com/',
          linkedin: 'linkedin.com/company/',
          youtube: 'youtube.com/c/',
        },
        socialItens: [
          {
            label: 'Facebook',
            tag: 'facebook',
            order: 0,
          },
          {
            label: 'Instagram',
            tag: 'instagram',
            order: 1,
          },
          {
            label: 'Linkedin',
            tag: 'linkedin',
            order: 2,
          },
          {
            label: 'Twitter',
            tag: 'twitter',
            order: 3,
          },
          {
            label: 'Youtube',
            tag: 'youtube',
            order: 4,
          },
        ]
      }
    },
    created() {
      this.social = { 
        facebook: this.facebook,
        twitter: this.instagram,
        instagram: this.linkedin,
        linkedin: this.twitter,
        youtube: this.youtube,
      }
    },
    methods: {
      ...mapMutations(["setSocialLink"]),
    },
    computed: {
      ...mapGetters(["facebook", "instagram", "linkedin", "twitter", "youtube"]),
    }
 }
</script>