<template>
  <v-card flat>
    <v-card-text>
      <v-row class="ma-0">
        <v-col cols="9" class="">
          <v-card-title class="font-weight-black px-0 mx-0 text-body-1"
            >Informações básicas</v-card-title
          >

          <v-text-field
            @input="setName(userName)"
            v-model="userName"
            label="Nome completo"
            color="secondary"
          />

          <!-- <v-text-field 
            v-model="userCompanyName"
            @input="setCompany(userCompanyName)"
            label="Nome da empresa" color="secondary" /> -->

          <v-text-field
            v-model="userRole"
            @input="setRole(userRole)"
            label="Cargo"
            color="secondary"
          />

          <v-card-title class="font-weight-black px-0 mx-0 text-body-1"
            >Informações de contato</v-card-title
          >
          <v-text-field
            v-model="userSite"
            @input="setSite(userSite)"
            label="Website da empresa"
            color="secondary"
            :rules="[rules.website]"
          />

          <v-text-field
            v-model="userEmail"
            @input="setEmail(userEmail)"
            label="E-mail"
            color="secondary"
          />

          <!-- <v-text-field
            v-model="userAddress"
            @input="setAddress(userAddress)"
            label="Endereço completo"
            color="secondary" /> -->

          <v-row class="ma-0" no-gutters>
            <v-col class="mr-2">
              <v-text-field
                v-model="userPhone"
                @input="setPhone(userPhone)"
                v-mask="['(##) #####-####', '(##) ####-####']"
                label="Telefone"
                color="secondary"
              />
            </v-col>
            <v-col class="ml-2">
              <v-text-field
                v-model="userCellphone"
                @input="setCellphone(userCellphone)"
                v-mask="['(##) #####-####', '(##) ####-####']"
                label="Celular"
                color="secondary"
              />
            </v-col>
          </v-row>

          <v-checkbox
            :disabled="hasCellphone"
            @change="setIsWhatsapp(isUserWhatsapp)"
            v-model="isUserWhatsapp"
            dense
            label="O número de celular informado é um número do Whatsapp"
            color="success"
          />
        </v-col>
        <v-col cols="3" class="d-flex flex-column justify-space-between">
          <!-- ÁREA DAS IMAGENS -->
          <div
            class="pointer d-flex flex-column align-center mt-8"
            @click="open(true)"
          >
            <v-badge
              bordered
              color="secondary"
              icon="mdi-plus"
              offset-x="25"
              offset-y="25"
              overlap
              bottom
            >
              <v-icon
                v-if="!url && !avatarUrl"
                v-text="'mdi-account-circle'"
                color="secondary"
                size="80"
              />
              <v-avatar v-else size="80">
                <v-img
                  max-height="80"
                  max-width="80"
                  class="mx-auto my-4"
                  :src="url ? url : avatarUrl"
                  alt="Avatar"
                  @error="loadImageError()"
                  @load="loadImage()"
                ></v-img>
              </v-avatar>
            </v-badge>
            <strong class="text-color font-weight-bold mt-1 text-center"
              >Alterar avatar do usuário</strong
            >
          </div>

          <div
            class="pointer d-flex flex-column align-center justify-center mb-14"
            @click="open()"
          >
            <v-icon
              v-if="!url && !logoUrl"
              v-text="'mdi-image-plus'"
              color="secondary"
              size="60"
            />
            <v-img
              v-else
              max-height="80"
              max-width="80"
              class="mx-auto my-4"
              :src="url ? url : logoUrl"
              alt="logo da empresa"
              @error="loadImageError()"
              @load="loadImage()"
            ></v-img>
            <strong class="text-color font-weight-bold mt-1 text-center"
              >Inserir logo da empresa</strong
            >
          </div>
          <div></div>
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog
      v-model="dialog"
      @keydown.esc="close()"
      @click:outside="close()"
      max-width="800"
    >
      <v-card class="py-4">
        <v-card-title class="text-h5 font-weight-medium">
          {{ isAvatar ? "Adicionar avatar" : "Adicionar logo da empresa" }}
          <v-spacer></v-spacer>
          <v-btn icon @click="close()">
            <v-icon v-text="'mdi-close'" />
          </v-btn>
        </v-card-title>
        <v-card-subtitle class="mt-1">
          Adicione um link válido de uma imagem sua para exibir na assinatura
          (somente nos modelos com imagem).
        </v-card-subtitle>
        <v-card-text>
          <v-alert border="left" text type="info" class="text-body-2">
            <strong>Sugestão: </strong> utilize uma imagem armazenada no Google
            Drive.
            <div>
              <ol>
                <li>
                  Com o botão direito do mouse, clique sobre a imagem e
                  selecione "Compartilhar";
                </li>
                <li>
                  Cole o link abaixo. Este link será automaticamente atualizado
                  pela nossa ferramenta para que seja utilizado em sua
                  assinatura de e-mail.
                </li>
                <li>
                  Em "Copiar link", escolha a opção "leitor" que permitirá que
                  qualquer pessoa com o link possa visualizar a imagem. Clique
                  em "copiar link"
                </li>
              </ol>
            </div>
          </v-alert>

          <v-text-field
            :label="isAvatar ? 'Url do avatar' : 'Url da logo'"
            v-model="url"
            color="secondary"
          >
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-2" text @click="close()"> Cancelar </v-btn>
          <v-btn
            color="secondary"
            class="mr-8"
            @click="saveUrl()"
            :disabled="!isValidImage"
          >
            Inserir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { mapMutations, mapGetters } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      url: "",
      isAvatar: true,
      isValidImage: false,

      userName: "",
      userCompanyName: "",
      userRole: "",
      userSite: "",
      userEmail: "",
      userAddress: "",
      userPhone: "",
      userCellphone: "",
      isUserWhatsapp: false,
      rules: {
        website: (value) =>
          (value && value.includes(".")) ||
          "Insira um endereço eletrônico válido",
      },
    };
  },
  created() {
    this.userName = this.name;
    this.userCompanyName = this.company;
    this.userRole = this.role;
    this.userSite = this.site;
    this.userEmail = this.email;
    this.userAddress = this.address;
    this.userPhone = this.phone;
    this.userCellphone = this.cellPhone;
    this.isUserWhatsapp = this.isWhatsapp;
  },
  computed: {
    ...mapGetters([
      "name",
      "company",
      "role",
      "site",
      "email",
      "address",
      "phone",
      "cellphone",
      "isWhatsapp",
      "logoUrl",
      "avatarUrl",
    ]),
    isGoogleDriveUrl() {
      return this.url.startsWith("https://drive.google.com/file/d/") &&
        this.url !== ""
        ? true
        : false;
    },

    hasCellphone() {
      return !this.cellphone && this.userCellphone !== "";
    },
  },

  methods: {
    ...mapMutations([
      "setName",
      "setCompany",
      "setRole",
      "setEmail",
      "setSite",
      "setRole",
      "setPhone",
      "setAddress",
      "setCellphone",
      "setIsWhatsapp",
      "setLogoUrl",
      "setAvatarUrl",
    ]),

    open(isAvatar = false) {
      this.dialog = true;
      this.isAvatar = isAvatar;
    },

    loadImage() {
      this.isValidImage = true;
    },

    loadImageError() {
      this.isValidImage = false;
    },

    showImageDialog() {
      this.dialog = true;
    },

    formatGoogleDriveUrl() {
      this.url = this.url.replace(
        "https://drive.google.com/file/d/",
        "https://drive.google.com/uc?id="
      );
      this.url = this.url.replace("/view?usp=sharing", "&amp;export=download");
      if (this.url.startsWith("https://drive.google.com/uc?id=")) {
        const id = /id=(.*?)&/.exec(this.url)[1];
        this.url = `https://lh3.googleusercontent.com/d/${id}`;
      }
    },

    saveUrl() {
      this.isAvatar ? this.setAvatarUrl(this.url) : this.setLogoUrl(this.url);
      this.close();
    },

    close() {
      this.dialog = false;
      this.url = "";
      this.isValidImage = false;
    },
  },

  watch: {
    url() {
      if (this.isGoogleDriveUrl) {
        this.formatGoogleDriveUrl();
      }
    },
  },
};
</script>
<style scoped>
.pointer {
  cursor: pointer;
}

ol li {
  padding: 0.5rem 0.1rem;
}
</style>
