<!-- eslint-disable vue/no-parsing-error -->
<template>
    <div id="signatureC01">
        <p
            :style="`font-family: ${typography.textFont}, geneva, sans-serif; border 1px solid; font-size: ${typography.textFontSize}; font-weight:bold; color:${textColor};`">
            <span>
                Atenciosamente,
            </span>
        </p>
        <table style="border-collapse: collapse; width: 602px; height: 38px;" border="0">
            <tbody>
                <tr style="height: 130px;">
                    <td style="width: 10px; height: 28px;">
                        <div>
                            <img style="max-width:150px; border-radius:3%; margin: 0px auto 5px auto; object-fit: cover;"
                                :src="getURLAvatar" alt="Avatar" width="150" height="150" />
                        </div>
                        <div v-if="logoUrl">
                            <img
                                style="max-height: 150px; max-width: 150px;"
                                width="150"
                                :src="getURLCompanyLogo"
                                alt="Logotipo da Empresa"
                            />
                        </div>
                    </td>
                    <td style="width: 10px; height: 28px;">
                        <p
                            :style="`font-family: ${typography.textFont} -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; text-align: left;`">
                            <span :style="`font-family: ${typography.textFont} verdana, geneva, sans-serif;`">
                                <strong><span style="`font-size: 12pt;`">&nbsp;</span></strong>
                            </span>
                        </p>
                    </td>
                    <td style="height: 28px; width: 370px;">
                        <p
                            :style="`font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; text-align: left;`">
                            <span
                                :style="`font-family: ${typography.titleFont}, geneva, sans-serif; color:${titleColor}; font-size: ${typography.titleFontSize}; line-height: 1.2em;`">
                                <strong>{{ name || "Seu nome" }}</strong>
                            </span>
                            <br />
                            <span
                                :style="`font-family: ${typography.textFont}, geneva, sans-serif; border 1px solid; font-size: ${typography.textFontSize}; font-weight:bold; color:${textColor};`">
                                <i>{{ role || "Seu Cargo" }}</i></span>
                            <br />
                            <span
                                :style="`font-family: ${typography.textFont}, geneva, sans-serif; border 1px solid; font-size: ${typography.textFontSize}; font-weight:normal; color:${textColor};`">{{
                                    email || "Email" }}</span>
                        </p>
                        <p
                            :style="`font-family: ${typography.textFont} -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; text-align: left;`">
                            <span
                                :style="`font-family: ${typography.textFont}, verdana, geneva, sans-serif; font-size: ${typography.textFontSize}; color: #636363;`">
                                <span :style="`color: ${textColor};`">
                                    <strong>
                                        <span :style="`font-family: ${typography.textFont}, verdana, geneva, sans-serif;`">
                                            {{ [phone, cellphone].filter(v => v).join(' / ') || "(11) 31234-5678 / (11) 98080 - 9090" }}
                                            <br />
                                        </span>
                                    </strong>
                                </span>
                            </span>
                            <span
                                :style="`font-family: ${typography.textFont} verdana, geneva, sans-serif; font-size: ${typography.textFontSize}; text-decoration: none; color:${titleColor}`">
                                <a :href="site" :style="`color: ${titleColor}`">
                                    {{ site || "www.seusite.com" }}
                                </a>
                            </span>
                            <span
                                :style="`font-family: ${typography.textFont} verdana, geneva, sans-serif; font-size: ${typography.textFontSize}; color: #636363;`">
                                <span style="color: #636363;">
                                    <span style="font-family: verdana, geneva, sans-serif;">
                                        <br />
                                    </span>
                                </span>
                            </span>
                        </p>
                        <div>
                            <span v-if="redesSociaisComCor" style="display: flex;
                              flex-direction: row;
                              align-items: center;
                              margin-top: 5px;
                              width: 160px;
                              height: 28px;
                            ">
                                <facebook size="28" :color="corRedesSociais" />
                                <instagram size="28" :color="corRedesSociais" />
                                <linkedin size="28" :color="corRedesSociais" />
                                <twitter size="28" :color="corRedesSociais" />
                                <youtube size="28" :color="corRedesSociais" />
                                <whatsapp size="28" :color="corRedesSociais" />
                            </span>

                            <span v-else style="
                              display: flex;
                              flex-direction: row;
                              align-items: center;
                              margin-top: 5px;
                              width: 160px;
                              height: 28px;
                            ">
                                <facebook size="28" />
                                <instagram size="28" />
                                <linkedin size="28" />
                                <twitter size="28" />
                                <youtube size="28" />
                                <whatsapp size="28" />
                            </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td style="width: 10px;">
                        <p>&nbsp;</p>
                    </td>
                    <td style="width: 10px;">
                        <p
                            :style="`font-family: ${typography.textFont} -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; text-align: left;`">
                            <span :style="` ${typography.textFont} font-family: verdana, geneva, sans-serif;`">
                                <strong><span
                                        :style="`font-size: ${typography.textFontSize};`">&nbsp;</span></strong></span>
                        </p>
                    </td>
                    <td style="width: 370px;">&nbsp;
                    </td>
                </tr>
                <tr>
                    <td style="width: 10px;" colspan="3">
                        <hr />
                        <p>
                            <span
                                :style="`color: #7e8c8d; font-family: ${typography.textFont}  Roboto, RobotoDraft, Helvetica, Arial, sans-serif; font-size: 8pt; background-color: #ffffff;`">Esta
                                mensagem pode conter informa&ccedil;&atilde;o confidencial ou privilegiada, sendo seu sigilo
                                protegido por lei. Se voc&ecirc; n&atilde;o for o destinat&aacute;rio ou a pessoa autorizada
                                a receber esta mensagem, n&atilde;o pode usar, copiar ou divulgar as
                                informa&ccedil;&otilde;es nela contidas ou tomar qualquer a&ccedil;&atilde;o baseada nessas
                                informa&ccedil;&otilde;es. Se voc&ecirc; recebeu esta mensagem por engano, por favor, avise
                                imediatamente ao remetente, respondendo o e-mail e em seguida apague-a. Agradecemos sua
                                coopera&ccedil;&atilde;o.
                            </span>
                        </p>
                        <p>
                            <span style="font-size: 8pt;">
                                <span
                                    :style="`font-family: ${typography.textFont}  Roboto, RobotoDraft, Helvetica, Arial, sans-serif; background-color: #ffffff; color: ${titleColor};`">Antes
                                    de imprimir, pense em sua responsabilidade e compromisso com o meio ambiente.
                                </span>
                            </span>
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import Facebook from "@/components/social/Facebook.vue";
import Linkedin from "@/components/social/Linkedin.vue";
import Youtube from "@/components/social/Youtube.vue";
import Twitter from "@/components/social/Twitter.vue";
import Instagram from "@/components/social/Instagram.vue";
import Whatsapp from "@/components/social/Whatsapp.vue";
import { defaultUrlAvatar, defaultLogoUrl } from "@/helpers/services/utils";
import { mapGetters } from "vuex";

export default {
    components: { Facebook, Linkedin, Youtube, Twitter, Instagram, Whatsapp },

    data() {
        return {
            defaultUrlAvatar,
            defaultLogoUrl,
        };
    },

    computed: {
        ...mapGetters([
            "name",
            "email",
            "phone",
            "cellphone",
            "site",
            "role",
            "redesSociaisComCor",
            "corRedesSociais",
            "titleColor",
            "textColor",
            "avatarUrl",
            "typography",
            "logoUrl"
        ]),

        getURLAvatar() {
            return this.avatarUrl || this.defaultUrlAvatar;
        },
        getURLCompanyLogo() {
            return this.logoUrl || this.defaultLogoUrl;
        },
    },
};
</script>